* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  color: #c53a3f;
  margin: 0;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-weight: 900;
  letter-spacing: 0.04em;
  white-space: nowrap;
}
h2 {
  color: #fb5d38;
  margin: 0;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-weight: 900;
  letter-spacing: 0.04em;
  white-space: nowrap;
}

.about {
  color: #1199f3;
  margin: 0;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-weight: 900;
  letter-spacing: 0.04em;
  white-space: nowrap;
}
